// main.js

// jQuery scripts in here:
jQuery(function ($) {

	$(document).ready(function(){
		$('.bxslider').bxSlider({
			mode: 'fade',
			pagerCustom: '#bx-pager'
		});
    	$('.pod').matchHeight({
    		byRow: false,
    		property: 'height',
    		target: null,
    		remove: false
		});
	});
	

});
